<template>
  <v-row v-if="loading" align="center" justify="center" class="fill-height">
    <v-progress-circular
      :size="60"
      :width="5"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-row>

  <v-container v-else :class="{ 'pa-0': chosen }">
    <v-card
      max-width="547"
      :class="['mx-auto', chosen ? 'pa-0' : 'py-6 px-3 px-md-11']"
    >
      <div v-if="!chosen" class="mb-4">
        <span class="font-regular-16 gray10--text me-1">
          {{ $t("panel.businessesPage.createPosGateway.businessName") }}
        </span>

        <span class="font-regular-16 gray13--text me-1">
          {{ businessName }}
        </span>
      </div>

      <v-form ref="form" v-model="valid" @submit.prevent="submit">
        <v-text-field
          name="selectedCurrenciesNames"
          :value="selectedCurrenciesNames"
          :rules="currenciesRules"
          required
          :label="$t('panel.businessesPage.createPosGateway.currency')"
          filled
          readonly
          hide-details="auto"
          class="theme-custom-input mb-6"
          @click="openCurrenciesModal"
        >
          <template v-slot:append>
            <v-icon color="transparent" @click="openCurrenciesModal"
              >$arrowDown</v-icon
            >
          </template>
        </v-text-field>

        <v-checkbox
          name="gatewayPosType"
          v-model="gatewayPosType"
          dense
          :ripple="false"
          hide-details
          off-icon="$emptyCheckbox"
          on-icon="$activeCheckbox"
          class="mb-6"
        >
          <template v-slot:label>
            <div class="gray13--text">
              {{ $t("panel.businessesPage.createPosGateway.needPosHardware") }}
            </div>

            <v-tooltip
              v-model="showGatewayPosTypeTooltip"
              top
              color="white"
              content-class="pos-hardware-tooltip"
              class="pos-hardware-tooltip"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="16"
                  color="transparent"
                  class="ms-1"
                  v-bind="attrs"
                  v-on="on"
                  >$info
                </v-icon>
              </template>
              <span class="gray8--text">
                {{
                  $t(
                    "panel.businessesPage.createPosGateway.posHardwareTooltipDesc"
                  )
                }}
              </span>
            </v-tooltip>
          </template>
        </v-checkbox>

        <captcha
          class="mb-16"
          :reload="reloadCaptcha"
          @setCaptchaInfo="setCaptchaInfo"
        />

        <v-btn
          :disabled="!valid"
          color="primary"
          block
          depressed
          height="56"
          class="mb-2"
          :loading="submitLoading"
          type="submit"
        >
          <span class="font-semiBold-14">
            {{ $t("panel.businessesPage.createPosGateway.complete") }}
          </span>
        </v-btn>
      </v-form>

      <v-dialog v-model="currenciesModal" width="468" persistent>
        <currencies-modal
          :currencies="currencies"
          :gatewayCurrencies="gatewayModel.currenciesDto"
          @setCurrencies="setCurrencies"
          @close="closeModal"
        />
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import { MerchantService, CurrenciesService } from "@/services";
import CurrenciesModal from "../CreateGateway/CurrenciesModal";
import captcha from "@/components/Auth/Captcha";

export default {
  name: "create-pos-gateway",
  props: ["businessId", "chosen"],
  components: {
    CurrenciesModal,
    captcha,
  },
  data() {
    return {
      businessName: "",
      valid: false,
      loading: false,
      submitLoading: false,
      currenciesModal: false,
      showGatewayPosTypeTooltip: false,
      currencies: [],
      tempCurrencies: [],
      gatewayPosType: false,
      gatewayModel: {
        gatewayType: "POS",
        gatewayPosType: "",
        currenciesDto: [],
        captchaAnswer: "",
        captchaKey: "",
      },
      currenciesRules: [
        (v) =>
          !!v.length ||
          this.$t("panel.businessesPage.createPosGateway.currenciesNotEmpty"),
      ],
      reloadCaptcha: false,
    };
  },
  mounted() {
    this.loading = true;
    Promise.all([this.getMerchant(), this.getCurrencies()]).then(() => {
      this.loading = false;
    });
  },
  computed: {
    selectedCurrenciesNames: {
      get() {
        if (
          JSON.stringify(this.gatewayModel.currenciesDto) ===
          JSON.stringify(this.tempCurrencies)
        ) {
          return this.$t("panel.businessesPage.createPosGateway.allCurrencies");
        } else {
          return this.gatewayModel.currenciesDto.map((elem) => {
            return " " + elem.iso + " (" + elem.network + ")";
          });
        }
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    getMerchant() {
      return MerchantService.getMerchantById(this.businessId).then((res) => {
        this.businessName = res.data.data.businessName;
      });
    },
    getCurrencies() {
      return CurrenciesService.getSupportedCurrencies().then((res) => {
        this.currencies = res.data.data.content;
        this.manipulateCurrenciesList();
      });
    },
    openCurrenciesModal() {
      this.currenciesModal = true;
    },
    manipulateCurrenciesList() {
      this.tempCurrencies = this.currencies.map((elem) => {
        return {
          currencyId: elem.currencyId,
          iso: elem.iso,
          network: elem.network,
        };
      });
      this.gatewayModel.currenciesDto = [...this.tempCurrencies];
    },
    setCurrencies(currencies) {
      this.gatewayModel.currenciesDto = [...currencies];
      this.closeModal();
    },
    closeModal() {
      this.currenciesModal = false;
    },
    submit() {
      this.submitLoading = true;
      this.reloadCaptcha = false;
      this.gatewayModel.merchantId = this.businessId;

      if (this.gatewayPosType) this.gatewayModel.gatewayPosType = "HARDWARE";
      else this.gatewayModel.gatewayPosType = "SOFTWARE";
      MerchantService.addGateway(this.gatewayModel)
        .then(() => {
          this.$router.replace(
            `/panel/businesses/${this.businessId}/create-pos-gateway-report`
          );
        })
        .finally(() => {
          this.submitLoading = false;
          this.reloadCaptcha = true;
        });
    },
    setCaptchaInfo(data) {
      this.gatewayModel.captchaAnswer = data.captchaAnswer;
      this.gatewayModel.captchaKey = data.captchaKey;
    },
  },
};
</script>

<style scoped lang="scss">
@import "CreatePosGateway";
</style>
