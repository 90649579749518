<template>
  <v-row v-if="loading" align="center" justify="center" class="fill-height">
    <v-progress-circular
      :size="60"
      :width="5"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-row>

  <v-container v-else>
    <v-card
      min-height="436"
      max-width="547"
      class="mx-auto pb-8 pt-6 px-3 px-md-11 d-flex flex-column"
    >
      <div class="mb-4">
        <span class="font-regular-16 gray10--text me-1">
          {{ $t("panel.businessesPage.createGateway.businessName") }}
        </span>

        <span class="font-regular-16 gray13--text me-1">
          {{ businessName }}
        </span>
      </div>

      <v-radio-group
        v-model="gatewayModel.gatewayType"
        required
        :column="$vuetify.breakpoint.mdAndDown"
        class="mb-6"
        hide-details
      >
        <v-radio
          value="WEB"
          :ripple="false"
          on-icon="$activeCircle"
          off-icon="$emptyCircle"
          class="me-5"
        >
          <template v-slot:label>
            <span class="gray13--text">
              {{ $t("panel.businessesPage.createGateway.webGateway") }}
            </span>
          </template>
        </v-radio>

        <v-radio
          value="POS"
          :ripple="false"
          on-icon="$activeCircle"
          off-icon="$emptyCircle"
        >
          <template v-slot:label>
            <span class="gray13--text">
              {{ $t("panel.businessesPage.createGateway.posGateway") }}
            </span>
          </template>
        </v-radio>
      </v-radio-group>

      <transition mode="out-in" name="route">
        <div v-if="gatewayModel.gatewayType === 'WEB'" class="my-auto">
          <CreateWebGateway :businessId="businessId" :chosen="true" />
        </div>
        <section v-else-if="gatewayModel.gatewayType === 'POS'" class="my-auto">
          <CreatePosGateway :businessId="businessId" :chosen="true" />
        </section>
      </transition>
    </v-card>
  </v-container>
</template>

<script>
import { MerchantService } from "@/services";
import CreateWebGateway from "@/components/Businesses/CreateWebGateway";
import CreatePosGateway from "@/components/Businesses/CreatePosGateway";

export default {
  name: "create-gateway",
  props: ["businessId"],
  components: {
    CreateWebGateway,
    CreatePosGateway,
  },
  data() {
    return {
      businessName: "",
      loading: false,
      gatewayModel: {
        gatewayType: "",
      },
    };
  },
  async mounted() {
    this.loading = true;
    await this.getMerchant();
    this.loading = false;
  },
  computed: {},
  methods: {
    async getMerchant() {
      return await MerchantService.getMerchantById(this.businessId).then(
        (res) => {
          this.businessName = res.data.data.businessName;
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "CreateGateway";
</style>
